import React from "react";
import { useForm } from "react-hook-form";
import { Box, TextField, Button, Typography } from "@mui/material";
import FieldContainer from "../components/shared/form/dynamic/components/FieldContainer";
import FiledLabel from "../components/shared/form/dynamic/components/FiledLabel";
import { useMutateData } from "../api/axios";

const AddCustomerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading, isError, isSuccess } = useMutateData({
    method: "POST",
    url: "/customer",
  });

  const onSubmit = (formData) => {
    mutate(formData, {
      onSuccess: (response) => {
        // Log the full response from the backend
        console.log("Customer successfully added!");
        console.log("Backend Response:", response.data); // Access response data from the backend

        // Optionally reset form or display success message
      },
      onError: (error) => {
        // Log any errors from the backend
        console.error(
          "Error adding customer:",
          error.response?.data || error.message
        );
      },
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: "500px",
        mt: 4,
      }}
    >
      {/* Customer ID */}
      <FieldContainer>
        <FiledLabel htmlFor="customerId">Customer ID</FiledLabel>
        <TextField
          fullWidth
          id="customerId"
          error={!!errors.customerId}
          helperText={errors.customerId?.message}
          {...register("customerId", { required: "Customer ID is required" })}
        />
      </FieldContainer>

      {/* Customer Name */}
      <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerName">Customer Name</FiledLabel>
        <TextField
          fullWidth
          id="customerName"
          error={!!errors.customerName}
          helperText={errors.customerName?.message}
          {...register("customerName", {
            required: "Customer Name is required",
          })}
        />
      </FieldContainer>

      {/* Customer State */}
      <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerState">Customer State</FiledLabel>
        <TextField
          fullWidth
          id="customerState"
          error={!!errors.customerState}
          helperText={errors.customerState?.message}
          {...register("customerState", {
            required: "Customer State is required",
          })}
        />
      </FieldContainer>

      {/* Customer City */}
      <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerCity">Customer City</FiledLabel>
        <TextField
          fullWidth
          id="customerCity"
          error={!!errors.customerCity}
          helperText={errors.customerCity?.message}
          {...register("customerCity", {
            required: "Customer City is required",
          })}
        />
      </FieldContainer>

      {/* Customer Country */}
      <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerCountry">Customer Country</FiledLabel>
        <TextField
          fullWidth
          id="customerCountry"
          error={!!errors.customerCountry}
          helperText={errors.customerCountry?.message}
          {...register("customerCountry", {
            required: "Customer Country is required",
          })}
        />
      </FieldContainer>

      {/* Postal Code */}
      <FieldContainer mb={2}>
        <FiledLabel htmlFor="postalCode">Postal Code</FiledLabel>
        <TextField
          fullWidth
          id="postalCode"
          error={!!errors.postalCode}
          helperText={errors.postalCode?.message}
          {...register("postalCode", {
            required: "Postal Code is required",
          })}
        />
      </FieldContainer>

      {/* Customer Contact Email */}
      {/* <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerContactEmail">
          Customer Contact Email
        </FiledLabel>
        <TextField
          fullWidth
          id="customerContactEmail"
          {...register("customerContactEmail")}
        />
      </FieldContainer> */}

      {/* Customer Contact Person */}
      {/* <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerConactPerson">
          Customer Contact Person
        </FiledLabel>
        <TextField
          fullWidth
          id="customerConactPerson"
          {...register("customerConactPerson")}
        />
      </FieldContainer> */}

      {/* Customer Contact Phone */}
      {/* <FieldContainer mb={2}>
        <FiledLabel htmlFor="customerConactPhone">
          Customer Contact Phone
        </FiledLabel>
        <TextField
          fullWidth
          id="customerConactPhone"
          {...register("customerConactPhone")}
        />
      </FieldContainer> */}

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        disabled={isLoading}
      >
        {isLoading ? "Submitting..." : "Add Customer"}
      </Button>

      {/* Feedback Messages */}
      {isSuccess && (
        <Typography color="green" sx={{ mt: 2 }}>
          Customer added successfully!
        </Typography>
      )}
      {isError && (
        <Typography color="red" sx={{ mt: 2 }}>
          Error adding customer. Please try again.
        </Typography>
      )}
    </Box>
  );
};

export default AddCustomerForm;
