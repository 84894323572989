import { useFetchData } from "../axios";

function useGetTableValues({ urlPrefix, orderId, name }) {
  const {
    data: tableData,
    error: tableError,
    isLoading: loadingTable,
  } = useFetchData({
    queryKey: [name],
    url: `${urlPrefix}/field?field=${encodeURIComponent(
      name
    )}&orderId=${encodeURIComponent(orderId)}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  return [tableData, tableError, loadingTable];
}

export default useGetTableValues;
