import { Button } from "@mui/material";
import React, { useState } from "react";
import AddCustomerForm from "../../config/AddCustomerForm";
import AddEmployeeForm from "./AddEmployeeForm";
import CustomerContactsTable from "./CustomerContactsTable";
import EmployeesTable from "./EmployeesTable";

const options = [
  {
    label: "Customer Contacts",
    name: "customerContacts",
  },
  {
    label: "Employees",
    name: "employees",
  },
  {
    label: "Add New employee",
    name: "addNewEmployee",
  },
];

function ManageUsers() {
  const [currentOption, setCurrentOption] = useState("employees");

  const handleChangeCurrentOption = (value) => {
    setCurrentOption(value);
  };
  return (
    <div>
      <UsersNav
        currentOption={currentOption}
        handleChangeCurrentOption={handleChangeCurrentOption}
      />
      {currentOption === "addNewEmployee" && <AddEmployeeForm />}
      {currentOption === "customerContacts" && <CustomerContactsTable />}
      {currentOption === "employees" && <EmployeesTable />}
    </div>
  );
}

export default ManageUsers;

function UsersNav({ currentOption, handleChangeCurrentOption }) {
  return (
    <div className="userSettingsNav">
      {options.map((o) => {
        return (
          <Button
            key={o.name}
            variant={currentOption === o.name ? "outlined" : "text"}
            onClick={() => {
              handleChangeCurrentOption(o.name);
            }}
          >
            {o.label}
          </Button>
        );
      })}
    </div>
  );
}
