import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
} from "@mui/material";
import useGetTableValues from "../../../api/hooks/useGetTableValues";
import { useMutateData } from "../../../api/axios";
import { useQueryClient } from "@tanstack/react-query";
import useHelperFunctions from "../../../helpers/functions";

function TableComponent({ urlPrefix, orderId, name }) {
  console.log(urlPrefix, "urlPrefix");
  const queryClient = useQueryClient();

  const { currentOrder } = useHelperFunctions();

  // Fetch existing data (runs array)
  const [tableData, tableError, loadingTable] = useGetTableValues({
    urlPrefix,
    orderId,
    name,
  });

  const [newRow, setNewRow] = useState({
    rpm: "",
    temp: "",
    vibrationFront: "",
    vibrationRear: "",
    amp: "",
    voltage: "",
    time: "",
  });

  const { mutate, isLoading } = useMutateData({
    method: "PATCH",
    url: `report/push/field/value/TestingAndBalancingReport/${currentOrder["TestingAndBalancingReport"]}`,
  });

  console.log(tableData);

  // Handle input change for new row
  const handleInputChange = (e, field) => {
    setNewRow({ ...newRow, [field]: e.target.value });
  };

  // Save new row to backend
  const handleSaveRow = () => {
    if (!newRow.rpm || !newRow.temp || !newRow.time) {
      alert("Please fill required fields!");
      return;
    }

    const payload = {
      orderId,
      field: name,
      value: newRow, // ✅ Push this object to 'runs' array
    };

    mutate(payload, {
      onSuccess: () => {
        console.log("Row added successfully");
        queryClient.invalidateQueries([name]); // Refresh data
        setNewRow({
          rpm: "",
          temp: "",
          vibrationFront: "",
          vibrationRear: "",
          amp: "",
          voltage: "",
          time: "",
        });
      },
      onError: (error) => {
        console.error("Error saving row:", error);
      },
    });
  };

  return (
    <Box sx={{ maxWidth: "1248px", mt: "-16px" }}>
      {loadingTable ? <p>Loading...</p> : null}

      {tableError ? <p>Error loading table</p> : null}

      {/* Table */}
      <TableContainer sx={{ color: "#616161" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#616161" }}>
                <strong>RPM</strong>
              </TableCell>
              <TableCell sx={{ color: "#616161" }}>
                <strong>Temp</strong>
              </TableCell>
              <TableCell sx={{ color: "#616161" }}>
                <strong>Vibration Front</strong>
              </TableCell>
              <TableCell sx={{ color: "#616161" }}>
                <strong>Vibration Rear</strong>
              </TableCell>
              <TableCell sx={{ color: "#616161" }}>
                <strong>Amp</strong>
              </TableCell>
              <TableCell sx={{ color: "#616161" }}>
                <strong>Voltage</strong>
              </TableCell>
              <TableCell sx={{ color: "#616161" }}>
                <strong>Time</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Render existing rows */}
            {tableData?.data?.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: "#616161" }}>{row.rpm}</TableCell>
                <TableCell sx={{ color: "#616161" }}>{row.temp}</TableCell>
                <TableCell sx={{ color: "#616161" }}>
                  {row.vibrationFront}
                </TableCell>
                <TableCell sx={{ color: "#616161" }}>
                  {row.vibrationRear}
                </TableCell>
                <TableCell sx={{ color: "#616161" }}>{row.amp}</TableCell>
                <TableCell sx={{ color: "#616161" }}>{row.voltage}</TableCell>
                <TableCell sx={{ color: "#616161" }}>{row.time}</TableCell>
              </TableRow>
            ))}

            {/* New Row Input Fields */}
            <TableRow>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.rpm}
                  onChange={(e) => handleInputChange(e, "rpm")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.temp}
                  onChange={(e) => handleInputChange(e, "temp")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.vibrationFront}
                  onChange={(e) => handleInputChange(e, "vibrationFront")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.vibrationRear}
                  onChange={(e) => handleInputChange(e, "vibrationRear")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.amp}
                  onChange={(e) => handleInputChange(e, "amp")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.voltage}
                  onChange={(e) => handleInputChange(e, "voltage")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={newRow.time}
                  onChange={(e) => handleInputChange(e, "time")}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Save Button */}
      <Button
        variant="outlined"
        color="primary"
        sx={{ my: 3, height: "32px", width: "120px" }}
        onClick={handleSaveRow}
        disabled={isLoading}
      >
        {isLoading ? "Saving..." : "Add Row"}
      </Button>
    </Box>
  );
}

export default TableComponent;
