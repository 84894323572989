const AllReports = {
  bt50: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    // {
    //   testName: "R/O On Pulley Dia - ?",
    //   fieldName: "boreRO",
    //   measured: "",
    //   key: "",
    //   notes: "",
    //   lowerLimit: "0.000",
    //   upperLimit: "0.005",
    //   units: "mm",
    //   remark: "",
    // },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "1100",
      upperLimit: "1800",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.180",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Blue Match",
      fieldName: "blueMatching",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "85",
      upperLimit: "100",
      units: "percent",
      remark: "",
    },
  ],
  hsk50: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    // {
    //   testName: "R/O On Pulley Dia - ?",
    //   fieldName: "boreRO",
    //   measured: "",
    //   key: "",
    //   notes: "",
    //   lowerLimit: "0.000",
    //   upperLimit: "0.005",
    //   units: "mm",
    //   remark: "",
    // },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "900",
      upperLimit: "1100",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.180",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Blue Match",
      fieldName: "blueMatching",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "85",
      upperLimit: "100",
      units: "percent",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
  ],
  bt40: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    // {
    //   testName: "R/O On Pulley Dia - ?",
    //   fieldName: "boreRO",
    //   measured: "",
    //   key: "",
    //   notes: "",
    //   lowerLimit: "0.000",
    //   upperLimit: "0.005",
    //   units: "mm",
    //   remark: "",
    // },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "750",
      upperLimit: "950",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.180",
      units: "mm",
      remark: "",
    },
    {
      testName: "Blue Match",
      fieldName: "blueMatching",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "85",
      upperLimit: "100",
      units: "percent",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
  ],
  hsk63: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    // {
    //   testName: "R/O On Pulley Dia - ?",
    //   fieldName: "boreRO",
    //   measured: "",
    //   key: "",
    //   notes: "",
    //   lowerLimit: "0.000",
    //   upperLimit: "0.005",
    //   units: "mm",
    //   remark: "",
    // },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "1800",
      upperLimit: "2400",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.180",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Blue Match",
      fieldName: "blueMatching",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "85",
      upperLimit: "100",
      units: "percent",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
  ],
  bt30: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    // {
    //   testName: "R/O On Pulley Dia - ?",
    //   fieldName: "boreRO",
    //   measured: "",
    //   key: "",
    //   notes: "",
    //   lowerLimit: "0.000",
    //   upperLimit: "0.005",
    //   units: "mm",
    //   remark: "",
    // },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "200",
      upperLimit: "350",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.180",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Blue Match",
      fieldName: "blueMatching",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "85",
      upperLimit: "100",
      units: "percent",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
  ],
  hsk100: [
    {
      testName: "R/O Shaft Bore",
      fieldName: "boreRO",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "mm",
      remark: "",
    },
    // {
    //   testName: "R/O On Pulley Dia - ?",
    //   fieldName: "boreRO",
    //   measured: "",
    //   key: "",
    //   notes: "",
    //   lowerLimit: "0.000",
    //   upperLimit: "0.005",
    //   units: "mm",
    //   remark: "",
    // },
    {
      testName: "Drawbar Clamping Force",
      fieldName: "clampingForce",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "3800",
      upperLimit: "5000",
      units: "kg-f",
      remark: "",
    },
    {
      testName: "Axial Float",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.180",
      units: "mm",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Stud",
      fieldName: "mandrelROWithStud",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Blue Match",
      fieldName: "blueMatching",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "85",
      upperLimit: "100",
      units: "percent",
      remark: "",
    },
    {
      testName: "R/O of Shaft On Drawbar",
      fieldName: "mandrelROWithDrawbar",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "mm",
      remark: "",
    },
    {
      testName: "Axil Play",
      fieldName: "axialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Radial Play",
      fieldName: "radialPlay",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.000",
      units: "mm",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Front",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
    {
      testName: "Residual Imbalance - Rear",
      fieldName: "axialFloat",
      measured: "",
      key: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "1.50",
      units: "mm/sec",
      remark: "",
    },
  ],
};

export default AllReports;
