import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { ToggleOn, ToggleOff } from "@mui/icons-material";
import useGetAllEmployees from "../../api/hooks/useGetAllEmployees";

const roleOptions = ["admin", "manager", "supervisor", "technician"];

function EmployeesTable() {
  const [
    allEmployeesData,
    loadingAllEmployeesData,
    errorLoadingAllEmployeesData,
    loadedAllEmployeesData,
  ] = useGetAllEmployees();

  if (!loadedAllEmployeesData || errorLoadingAllEmployeesData) {
    return <p>Something went wrong while loading the customer data</p>;
  }

  if (loadingAllEmployeesData) {
    return <p>Loading customer contact database</p>;
  }

  return (
    <div>
      <EmployeesTableComponent data={allEmployeesData.data} />
    </div>
  );
}

export default EmployeesTable;

const EmployeesTableComponent = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [userData, setUserData] = useState(data || []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleRoleChange = (userId, newRole) => {
    setUserData((prevData) =>
      prevData.map((user) =>
        user._id === userId ? { ...user, userRole: newRole } : user
      )
    );
    console.log(`Role updated for ${userId}: ${newRole}`);
  };

  const handleStatusToggle = (userId) => {
    setUserData((prevData) =>
      prevData.map((user) =>
        user._id === userId ? { ...user, isActive: !user.isActive } : user
      )
    );
    console.log(`Status toggled for ${userId}`);
  };

  // Filter data based on search
  const filteredData = userData?.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm) ||
      user.username.toLowerCase().includes(searchTerm)
  );

  return (
    <Box mt={3} sx={{ padding: "0 32px", maxWidth: "1080px" }}>
      {/* Search Input */}
      <TextField
        label="Search by Email or Username"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
      />

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Sr</strong>
              </TableCell>
              {/* <TableCell>
                <strong>Username</strong>
              </TableCell> */}
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>User Role</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => (
                <TableRow key={user._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  {/* <TableCell>{user.username}</TableCell> */}
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Select
                      value={user.userRole}
                      onChange={(e) =>
                        handleRoleChange(user._id, e.target.value)
                      }
                      sx={{ minWidth: 120 }}
                    >
                      {roleOptions.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell sx={{ width: "250px" }}>
                    <Typography
                      sx={{
                        color: user.isActive ? "green" : "red",
                        display: "inline-block",
                        marginRight: 1,
                      }}
                    >
                      {user.isActive ? "Active" : "Inactive"}
                    </Typography>
                    <IconButton
                      onClick={() => handleStatusToggle(user._id)}
                      color={user.isActive ? "success" : "error"}
                    >
                      {user.isActive ? (
                        <ToggleOn fontSize="large" />
                      ) : (
                        <ToggleOff fontSize="large" />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={filteredData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
      />
    </Box>
  );
};
