function generateUrl(currentOrder) {
  if (currentOrder[this.title]) {
    return `/${this.title}/${currentOrder[this.title]}`;
  } else {
    throw new Error(`Key "${this.title}" not found in currentOrder`);
  }
}

export const forms = {
  incomingAlert: [
    {
      title: "IncomingAlert",
      label: "Incoming Alert",
      description: "Details regarding the incoming alert for the order.",
      url: generateUrl,
    },
  ],
  dismantling: [
    {
      title: "CheckSheet",
      label: "Check Sheet",
      description: "Documentation of any identified damages.",
      url: generateUrl,
    },
    {
      title: "DamageReport",
      label: "Damage Report",
      description: "Documentation of any identified damages.",
      url: generateUrl,
    },
    {
      title: "OldBearingReport",
      label: "Old Bearing Report",
      description: "Analysis of the old bearings removed from the machinery.",
      url: generateUrl,
    },
    {
      title: "NewBearingReport",
      label: "New Bearing Report",
      description: "Details of the newly installed bearings.",
      url: generateUrl,
    },
    {
      title: "ElectricalTestReport",
      label: "Electrical Test Report",
      description: "Report of electrical testing and verification.",
      url: generateUrl,
    },
  ],
  inProcessInspection: [
    {
      title: "InProcessInspectionReport",
      label: "In Process Inspection Report",
      description: "Inspection during the processing phase of the order.",
      url: generateUrl,
    },
  ],
  finalInspection: [
    {
      title: "FinalInspectionReport",
      label: "Final Inspection Report",
      description: "Final inspection details before concluding the order.",
      url: generateUrl,
    },
    {
      title: "TestingAndBalancingReport",
      label: "Testing And Balancing Report",
      description: "Report covering the testing and balancing process.",
      url: generateUrl,
    },
  ],
  remarksForCustomer: [
    {
      title: "RemarksForCustomer",
      label: "Customer Remarks",
      description: "Remarks and notes provided to the customer.",
      url: generateUrl,
    },
  ],
};
