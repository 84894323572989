import useHelperFunctions from "../../helpers/functions";
import { useFetchData } from "../axios";

function useSpindleType() {
  const { currentOrder } = useHelperFunctions();
  const { data: spindleType } = useFetchData({
    queryKey: ["spindleType"],
    url: `/report/${"IncomingAlert"}/${
      currentOrder["IncomingAlert"]
    }/field?field=${encodeURIComponent(
      "spindleType"
    )}&orderId=${encodeURIComponent(currentOrder.orderId)}`,
  });

  return [spindleType ? spindleType?.data : "notMentioned"];
}

export default useSpindleType;
