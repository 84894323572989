import React, { useState } from "react";
import RemarksBox from "./RemarksBox";
import NoteUpload from "../../report/AddNote";
import { Box, Button } from "@mui/material";

function RemarksForCustomer({ currentReport, currentReportId, currentOrder }) {
  const [isNoteBoxVisible, setIsNoteBoxVisible] = useState(false);
  const toggleNoteBox = () => {
    setIsNoteBoxVisible((prev) => !prev);
  };

  return (
    <div>
      <RemarksBox
        currentReport={currentReport}
        currentReportId={currentReportId}
        currentOrder={currentOrder}
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <Button
          variant="outlined"
          onClick={toggleNoteBox}
          sx={{ alignSelf: "flex-start" }}
        >
          {isNoteBoxVisible ? "Close notepad" : "Add New Note"}
        </Button>
        {isNoteBoxVisible && (
          <NoteUpload
            url={`report/${currentReport}/${currentReportId}/add-customer-remark`}
            orderId={currentOrder["orderId"]}
            invalidationQuery={"order-remarks"}
            setIsNoteBoxVisible={setIsNoteBoxVisible}
          />
        )}
      </Box>
    </div>
  );
}

export default RemarksForCustomer;
