import useHelperFunctions from "../../helpers/functions";
import "./report.css";

import { Button, Typography } from "@mui/material";
import GroupedReportFields from "../shared/renders/GroupedReportFields";
import { lookup } from "../../config/lookup/lookup";
import { useLocation } from "react-router-dom";
import NotesImages from "./NotesImages";
import TestingAndBalancing from "../seperateForms/TestingAndBalancing/TestingAndBalancing";
import FinalInspection from "../seperateForms/FinalInspection/FinalInspection";
import RemarksForCustomer from "../seperateForms/RemarksForCustomer/RemarksForCustomer";
import IncomingAlert from "../seperateForms/IncomingAlert/IncomingAlert";
import InProcessInspection from "../seperateForms/InProcessInspectionReport/InProcessInspectionReport";
import CheckSheet from "../seperateForms/checkSheet/CheckSheet";
import Damage from "../seperateForms/damageReport/Damage";
import OldBearing from "../seperateForms/oldBearingReport/OldBearing";
import NewBearing from "../seperateForms/newBearingReoport/NewBearing";
import ElectricalInspection from "../seperateForms/ElectricalInspectionReport/ElectricalInspection";
// import { RemarksForCustomer } from "../../../../server/models/reports";

function ReportPage() {
  const { navigate, showOutlet, currentOrder } = useHelperFunctions();

  let location = useLocation();

  /**
   * location returns:
   *
   * hash: ""
   * key: "co78f817"
   * pathname: "/IncomingAlert/67a216c2500c985a3ddb69e8"
   * search: ""
   * state: null
   *
   */
  let currentReport = location.pathname.split("/")[1];
  let currentReportId = location.pathname.split("/")[2];

  console.log(currentReport);

  /**
   * Loads UI to switch to a different report belonging to the current order
   */
  function handleOpenReport() {
    navigate(`/${currentOrder["orderId"]}`);
  }

  function handleOpenField(data, state) {
    navigate(data, { state });
    showOutlet();
  }

  /**
   * Fetch inward number to be displayed on report's header component
   */
  // const { data: inwardNumber } = useFetchData({
  //   queryKey: ["inwardNumberOnHeader"],
  //   url: `/order/${currentOrder.orderId}`,
  //   options: {
  //     refetchOnWindowFocus: true,
  //     refetchOnReconnect: true,
  //     refetchOnMount: "always",
  //     staleTime: 30 * 60 * 1000,
  //   },
  // });

  return (
    <div className="reportPageContainer">
      <div className="reportHeader">
        <div className="reportNav">
          <div className="reportNavActions">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenReport()}
            >
              Switch Report
            </Button>
          </div>

          <div className="reportNavInfo">
            <Typography variant="h5" color="primary" mark={false}>
              {lookup(currentReport)}
            </Typography>

            <Typography
              variant="h6"
              color="primary"
              sx={{ fontStyle: "oblique" }}
            >
              {currentOrder ? currentOrder["inwardNumber"] : ""}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              sx={{ fontStyle: "oblique" }}
            >
              {currentOrder ? currentOrder["customerName"] : ""}
            </Typography>
            <div style={{ height: "100%" }}></div>
          </div>
        </div>
      </div>
      <div className="reportPage">
        {currentReport !== "RemarksForCustomer" && (
          <NotesImages
            currentOrder={currentOrder}
            currentReport={currentReport}
            currentReportId={currentReportId}
          />
        )}
        {/*
         * Following adjustment made to render specific uis for specific reports
         */}
        {/* {currentReport === "TestingAndBalancingReport" && (
          <TestingAndBalancing />
        )} */}
        {currentReport === "IncomingAlert" && <IncomingAlert />}
        {currentReport === "FinalInspectionReport" && <FinalInspection />}
        {currentReport === "InProcessInspectionReport" && (
          <InProcessInspection />
        )}
        {currentReport === "CheckSheet" && <CheckSheet />}
        {currentReport === "DamageReport" && <Damage />}
        {currentReport === "OldBearingReport" && <OldBearing />}
        {currentReport === "NewBearingReport" && <NewBearing />}
        {currentReport === "ElectricalTestReport" && <ElectricalInspection />}
        {currentReport === "TestingAndBalancingReport" && (
          <TestingAndBalancing />
        )}

        {currentReport === "RemarksForCustomer" && (
          <RemarksForCustomer
            currentOrder={currentOrder}
            currentReport={currentReport}
            currentReportId={currentReportId}
          />
        )}

        {/*
         * You will have to add new report below until all reports have their own components
         */}
        {currentReport !== "TestingAndBalancingReport" &&
        currentReport !== "FinalInspectionReport" &&
        currentReport !== "IncomingAlert" &&
        currentReport !== "CheckSheet" &&
        currentReport !== "DamageReport" &&
        currentReport !== "RemarksForCustomer" &&
        currentReport !== "InProcessInspectionReport" ? (
          <GroupedReportFields handleClick={handleOpenField} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ReportPage;
