import { menuItems } from "../../../config/reports/sidebarContent";
import useHelperFunctions from "../../../helpers/functions";
import Footer from "./Footer";
import OptionsList from "./OptionsList";
import "./sidebar.css";

function SideBar() {
  const { isSidebarOpen, isTabletOrBelow, userRole } = useHelperFunctions();

  return (
    <>
      {userRole !== "customer" && (
        <div
          className="sideBar"
          style={{
            display: isTabletOrBelow && !isSidebarOpen ? "none" : "",
          }}
        >
          <OptionsList menuItems={menuItems} />
          <div className="sidebarFooter">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default SideBar;
