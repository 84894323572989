import React from "react";
import InputField from "./InputField";
import DropdownField from "./DropDownField";
import RadioButtonField from "./RadioButtonField";
import LongTextField from "./LongText";
import DateTimeField from "./DateTime";
// Import more field components as needed

const FieldRenderer = ({ field, ...props }) => {
  switch (field.type) {
    case "dropdown":
      return <DropdownField {...props} options={field.options || []} />;
    case "text":
    case "number":
      return <InputField {...props} />;
    case "longtext":
      return <LongTextField {...props} />;
    case "date":
      return <DateTimeField {...props} />;
    case "checkbox":
      return <input type="checkbox" {...props} checked={props.value} />;
    case "radio":
      return <RadioButtonField {...props} options={field.options || []} />;
    default:
      return <p>Unsupported Field Type: {field.type}</p>;
  }
};

export default FieldRenderer;
