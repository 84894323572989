import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TableSortLabel,
  Tooltip,
  Button,
  InputAdornment,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useUIStore from "../../../stores/useUiStore";
import SectionHeader from "../../shared/typography/SectionHeader";
import useHelperFunctions from "../../../helpers/functions";
import SearchIcon from "@mui/icons-material/Search";
import { useFetchData } from "../../../api/axios";
import useAllOrdersData from "../../../api/hooks/useAllOrdersData";

function AllOrders() {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("srNumber");
  const [sortDirection, setSortDirection] = useState("desc");
  const [tableData, setTableData] = useState([]);

  const { isTabletOrBelow } = useHelperFunctions();

  const [allOrders, allOrdersError, loadingAllOrders, fetchingAllOrders] =
    useAllOrdersData();

  useEffect(() => {
    console.log("Fetched data:", allOrders);
    setTableData(allOrders?.data || []);
  }, [allOrders]);

  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const handleSort = (column) => {
    const isDesc = sortColumn === column && sortDirection === "desc";
    setSortDirection(isDesc ? "asc" : "desc");
    setSortColumn(column);
  };

  const filteredData = tableData
    ?.filter(
      (row) =>
        row.inwardNumber?.toLowerCase().includes(searchTerm) ||
        row.clientName?.toLowerCase().includes(searchTerm)
    )
    .sort((a, b) => {
      // Always prioritize sorting by createdAt in descending order (latest first)
      const dateOrder = new Date(b.createdAt) - new Date(a.createdAt);
      if (dateOrder !== 0) return dateOrder; // If dates are different, use this order

      // Apply secondary sorting based on selected column
      if (sortColumn !== "registrationDate") {
        const order = sortDirection === "asc" ? -1 : 1;
        const aValue = a[sortColumn]?.toString().toLowerCase() || "";
        const bValue = b[sortColumn]?.toString().toLowerCase() || "";
        return aValue > bValue ? order : aValue < bValue ? -order : 0;
      }

      return dateOrder; // Fallback
    });

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Approval Pending";
      case 2:
        return "In Process";
      case 3:
        return "Completed";
      case 4:
        return "Dispatched";
      case 5:
        return "On Hold";
      default:
        return "Unknown Status";
    }
  };

  const { clearOrderIndex, setOrderIndex } = useHelperFunctions();
  const navigate = useNavigate();
  const hideOutlet = useUIStore((state) => state.hideOutlet);

  const handleOpenOrder = (row) => {
    clearOrderIndex();
    setOrderIndex(row);
    navigate(`/${row.orderId}`);
    hideOutlet();
  };

  return (
    <>
      <SectionHeader>Search amongst all orders</SectionHeader>

      <Paper elevation={0} sx={{ maxWidth: "932px", overflow: "hidden" }}>
        <TextField
          label="Search by Client Name or Inward Number"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TableContainer sx={{ maxHeight: isTabletOrBelow ? 440 : 550 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { label: "S. No.", key: "srNumber" },
                  { label: "Inward Number", key: "inwardNumber" },
                  { label: "Customer", key: "customerName" },
                  { label: "Registration Date", key: "registrationDate" },
                  // { label: "Stage", key: "stage" },
                  // { label: "Status", key: "status" },
                  { label: "Action", key: "action" },
                ].map((column) => (
                  <TableCell key={column.key}>
                    <TableSortLabel
                      active={sortColumn === column.key}
                      direction={
                        sortColumn === column.key ? sortDirection : "asc"
                      }
                      onClick={() => handleSort(column.key)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((row, index) => (
                <Tooltip title="Click to open" arrow key={row.orderId}>
                  <TableRow
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleOpenOrder(row)}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.inwardNumber || "N/A"}</TableCell>
                    <TableCell>{row.customerName || "N/A"}</TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </TableCell>
                    {/* <TableCell>{row.stage || "N/A"}</TableCell>
                    <TableCell>{getStatusText(row.status)}</TableCell> */}
                    <TableCell>
                      <Button size="small">Open</Button>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default AllOrders;
