import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useFetchData, useMutateData } from "../../../api/axios";

const DateTimeField = ({
  value: initialValue,
  name,
  orderId,
  urlPrefix,
  queryClient,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(""); // default to empty string
  const [showSuccess, setShowSuccess] = useState(false);

  const { data, error, isLoading } = useFetchData({
    queryKey: [name],
    url: `${urlPrefix}/field?field=${encodeURIComponent(
      name
    )}&orderId=${encodeURIComponent(orderId)}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  useEffect(() => {
    const formatDateTimeLocal = (isoString) => {
      if (!isoString) return "";
      const date = new Date(isoString);
      if (isNaN(date)) return "";
      const offsetDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return offsetDate.toISOString().slice(0, 16);
    };

    if (data?.data) {
      const formatted = formatDateTimeLocal(data.data);
      setValue(formatted);
    }
  }, [data]);

  const { mutate, error: mutationError } = useMutateData({
    method: "PATCH",
    url: `${urlPrefix}`,
  });

  const handleSave = () => {
    if (!isEditable) return;

    const payload = {
      field: name,
      value: value || null, // save null if the field is empty
      orderId: orderId,
    };

    mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries([name]);
        setIsEditable(false);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1000);
      },
      onError: (error) => {
        console.error("Save Error:", error);
      },
    });
  };

  return (
    <TextField
      type="datetime-local"
      fullWidth
      variant="outlined"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        setIsEditable(true);
      }}
      onBlur={handleSave}
      error={Boolean(error || mutationError)}
      InputProps={{
        startAdornment: (
          <>
            {isEditable && (
              <InputAdornment position="start">
                <IconButton onClick={handleSave}>
                  <SaveIcon color="primary" />
                </IconButton>
              </InputAdornment>
            )}
            {!isEditable && showSuccess && (
              <InputAdornment position="start">
                <IconButton disabled>
                  <CheckCircleIcon color="success" />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
        sx: {
          backgroundColor: isEditable ? "#f9f9f9" : "white",
        },
      }}
    />
  );
};

export default DateTimeField;
