import { useFetchData } from "../axios";

function useGetAllEmployees() {
  const {
    data: allEmployeesData,
    isLoading: loadingAllEmployeesData,
    isError: errorLoadingAllEmployeesData,
    isSuccess: loadedAllEmployeesData,
    refetch: refetchAllEmployees,
  } = useFetchData({
    queryKey: [`all-employees`],
    url: `/auth/all-employees`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });
  return [
    allEmployeesData,
    loadingAllEmployeesData,
    errorLoadingAllEmployeesData,
    loadedAllEmployeesData,
    refetchAllEmployees,
  ];
}

export default useGetAllEmployees;
