import React from "react";
import { useFetchData } from "../axios";

function useAllOrdersData() {
  const {
    data: allOrders,
    error: allOrdersError,
    isLoading: loadingAllOrders,
    isFetching: fetchingAllOrders,
  } = useFetchData({
    queryKey: ["orders"],
    url: "/order",
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });
  return [allOrders, allOrdersError, loadingAllOrders, fetchingAllOrders];
}

export default useAllOrdersData;
