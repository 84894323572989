import { useFetchData } from "../axios";

function useAssignedOrders(email) {
  const {
    data: assignedOrders,
    isLoading: loadingAssignedOrders,
    error: errorInAssignedOrders,
  } = useFetchData({
    queryKey: [`customerOrders`],
    url: `/customer/get-customer-orders?customerEmail=${email}`,
  });
  return [assignedOrders, loadingAssignedOrders, errorInAssignedOrders];
}

export default useAssignedOrders;
