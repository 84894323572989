import React from "react";
import { Box, Typography, List, ListItem } from "@mui/material";

const changelogData = [
  "Reinstated drop-down options for fields in damage-report",
  "Refactored Home page and removed redundant section of quick-links",
  "Added conditional rendering in customer contact person selection to prevent error when contact person is present on the customer but not in the database",
  "Fixed issue where customer email would remain unchanged even when customer was changed",
  "Removed alert that pops up on adding new customer contact email",
  "Added hook to fetch all orders",
  "All orders on /all-orders are now sorted in descending order by default",
  "Removed excess log statement",
  "Consistent form import: removed the reportData object",
  "Added comments in orderStages",
  "Removed redundant backend call to fetch current order data",
  "Changed the way fields render in finalInspectionReport. Trying to bring them as close to form as possible",
  "Updated user model to distinguish between employees and customers and added user roles",
  "Added a route and a controller to fetch the list of customerContactPersons",
  "Refactored settings menu to add tabs",
  "Added tabs under manage users menu",
  "Added new tab in setting navigation to add new bearing",
  "Added add new employee form",
  "Added a route and controller to add new employee",
  "Modified the findCustomerContact controller to fetch all customerContact when customerId is not provided",
  "Minor change in findCustomerContact to handle the case where all customer contacts are required",
  "Created a paginated table to display all customer contacts",
  "Added hook to fetch all employees data",
  "Added employee table component under manage user field",
  "Created paginated and searchable manage users table",
  "Modified employee table to change customer role and added toggle icon on active-inactive role",
  "Handled data loading delay in customer contacts table and employee table",
  "Added functionality to refetch customer table data after adding new employee",
  "Added background color to the sidebar and added scroll on screen resize",
  "Added background color to the footer",
  "Changed the background color of footer to #fcfcfc and added border on right",
  "Updated the report-form interface and other relevant style changes",
  "Updated in process inspection report to render in an updated way along with relevant changes in styles",
  "Updated checksheet to render as per new structure",
  "Updated damage report to render as per new structure",
  "Updated oldBearing report to render as per new structure",
  "Updated newBearingReport to render as per new structure",
  "Updated ElectricalTestReport to render as per new structure",
  "Modified the TestingAndBalancingReport database model to accommodate values after test in form as an object in an array instead of separate fields",
  "Added controller and routes to push value into an array in the report model specifically for testing and balancing",
  "Refactored code to separate the testing and balancing table from the rest of the report",
  "Implemented functionality to add as many rows in testing and balancing report as required",
  "Basic changes in table styling in testing and balancing report",
  "Added missing fields in the final inspection report pdf",
  "Testing and balancing table component reworked to display the exact number of tests that were carried",
  "Made a provision to add current order details to the navbar",
  "Added changelog and created the route for the same",
  "Configured backend for deployment",
  "Configured frontend for build and deployment: build version 3",
];

const changelogData2 = [
  "Added dateTime and long text fields",
  "Fixed issue wherein spindle model was not being displayed in the report",
  "Added keys to incoming alert form",
  "Fixed issue where date time was not being displayed",
];

const Changelog = () => {
  return (
    <Box minHeight="100vh">
      <Box
        sx={{
          maxWidth: "1280px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" fontWeight="bold" mb={2}>
            Changelog: 23 Mar 2025 (V3.1)
          </Typography>
          <List>
            {changelogData2.map((item, index) => (
              <ListItem key={index} sx={{ color: "text.primary" }}>
                {`${(index + 1).toString().padStart(2, "0")}. ${item}`}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" fontWeight="bold" mb={2}>
            Changelog: 3 Mar 2025 (V3)
          </Typography>
          <List>
            {changelogData.map((item, index) => (
              <ListItem key={index} sx={{ color: "text.primary" }}>
                {`${(index + 1).toString().padStart(2, "0")}. ${item}`}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Changelog;
