import React, { useState, useEffect } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Success feedback icon
import { useFetchData, useMutateData } from "../../../api/axios";
import { red } from "@mui/material/colors";

const RadioButtonField = ({
  value: initialValue,
  options = [],
  name,
  orderId,
  urlPrefix,
  queryClient,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [showSuccess, setShowSuccess] = useState(false);

  // Fetch existing field data
  const { data, error, isLoading } = useFetchData({
    queryKey: [name],
    url: `${urlPrefix}/field?field=${encodeURIComponent(
      name
    )}&orderId=${encodeURIComponent(orderId)}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  // Update value when API data is fetched
  useEffect(() => {
    if (data?.data !== undefined && value === initialValue) {
      setValue(data.data);
    }
  }, [data]);

  // Mutation to save the selected value
  const { mutate, error: mutationError } = useMutateData({
    method: "PATCH",
    url: `${urlPrefix}`,
  });

  const handleSave = () => {
    if (!isEditable) return;
    const payload = {
      field: name,
      value: value,
      orderId: orderId,
    };

    console.log("Saving:", payload);

    mutate(payload, {
      onSuccess: () => {
        console.log("Save Success");
        queryClient.invalidateQueries([name]); // Refetch query
        setIsEditable(false);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1000);
      },
      onError: (error) => {
        console.error("Save Error:", error);
      },
    });
  };

  return (
    <FormControl
      component="fieldset"
      sx={{
        width: "100%",
        display: "flex",
        border: "1px solid lightgrey",
        padding: "8px 16px",
        borderRadius: "4px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Save Button (Before the Radio Options) */}
        {isEditable && (
          <IconButton onClick={handleSave} sx={{ marginRight: 1 }}>
            <SaveIcon color="primary" />
          </IconButton>
        )}
        {!isEditable && showSuccess && (
          <IconButton disabled sx={{ marginRight: 1 }}>
            <CheckCircleIcon color="success" />
          </IconButton>
        )}

        {/* Radio Group */}
        <RadioGroup
          row
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setIsEditable(true);
          }}
          onBlur={handleSave}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </div>
    </FormControl>
  );
};
export default RadioButtonField;
