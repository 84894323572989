import { useFetchData } from "../axios";

function useCustomerData() {
  const {
    data: customerData,
    isLoading: loadingCustomerData,
    isError: errorLoadingCustomer,
    isSuccess: loadedCustomer,
  } = useFetchData({
    queryKey: ["all-customers"],
    url: "/customer",
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });
  return [
    customerData,
    loadingCustomerData,
    errorLoadingCustomer,
    loadedCustomer,
  ];
}

export default useCustomerData;
