import { Button } from "@mui/material";
import OrderAction from "./components/OrderAction";
import OrdersTable from "./components/OrdersTable";
import "./home.css";
import SectionHeader from "../shared/typography/SectionHeader";
import useHelperFunctions from "../../helpers/functions";

function Home() {
  const { userRole, navigate } = useHelperFunctions();

  if (userRole === "customer") {
    return (
      <Button variant="outlined" onClick={() => navigate("/me")}>
        Orders
      </Button>
    );
  }
  return (
    <div>
      <div className="childCard">
        <SectionHeader s={{ marginBottom: "24px" }}>
          Welcome to SpinTrack!
        </SectionHeader>
        {/* 
          Order Actions: Create new order and 
          Load existing order 
        */}
        <OrderAction />
      </div>
      <div className="childCard">
        <SectionHeader>Recent Orders</SectionHeader>
        {/* Recent Orders' table */}
        <OrdersTable />
      </div>
    </div>
  );
}

export default Home;

/*
<div className="childCard">
<SectionHeader>Quick Actions</SectionHeader>
<Typography gutterBottom>
  <Link to="/create-order">Create New Order</Link>
</Typography>
<Typography gutterBottom>
  <Link to="/order-dashboard">Current Order</Link>
</Typography>
<Typography gutterBottom>
  <Link to="/all-orders">Browse all orders</Link>
</Typography>
<Typography gutterBottom>
  <Link
    to="https://www.setco.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    Setco Spindles
  </Link>
</Typography>
</div>
*/
