import {
  // tr1_rpm,
  // tr1_temp,
  // tr1_vibrationFront,
  // tr1_vibrationRear,
  // tr1_amp,
  // tr1_voltage,
  // tr1_time,
  // tr2_rpm,
  // tr2_temp,
  // tr2_vibrationFront,
  // tr2_vibrationRear,
  // tr2_amp,
  // tr2_voltage,
  // tr2_time,
  // tr3_rpm,
  // tr3_temp,
  // tr3_vibrationFront,
  // tr3_vibrationRear,
  // tr3_amp,
  // tr3_voltage,
  // tr3_time,
  // tr4_rpm,
  // tr4_temp,
  // tr4_vibrationFront,
  // tr4_vibrationRear,
  // tr4_amp,
  // tr4_voltage,
  // tr4_time,
  // tr5_rpm,
  // tr5_temp,
  // tr5_vibrationFront,
  // tr5_vibrationRear,
  // tr5_amp,
  // tr5_voltage,
  // tr5_time,
  stabilizedTemp,
  maxTemp,
  residualImbalanceFront,
  residualImbalanceRear,
  remark,
} from "./TestingAndBalancingComponents";

export const TestingAndBalancingReport = [
  // {
  // 	groupHeader: 'Trial Run One ',
  // 	components: [
  // 		tr1_rpm,
  // 		tr1_temp,
  // 		tr1_vibrationFront,
  // 		tr1_vibrationRear,
  // 		tr1_amp,
  // 		tr1_voltage,
  // 		tr1_time,
  // 	],
  // },
  // {
  // 	groupHeader: 'Trial Run Two ',
  // 	components: [
  // 		tr2_rpm,
  // 		tr2_temp,
  // 		tr2_vibrationFront,
  // 		tr2_vibrationRear,
  // 		tr2_amp,
  // 		tr2_voltage,
  // 		tr2_time,
  // 	],
  // },
  // {
  // 	groupHeader: 'Trial Run Three ',
  // 	components: [
  // 		tr3_rpm,
  // 		tr3_temp,
  // 		tr3_vibrationFront,
  // 		tr3_vibrationRear,
  // 		tr3_amp,
  // 		tr3_voltage,
  // 		tr3_time,
  // 	],
  // },
  // {
  // 	groupHeader: 'Trial Run Four ',
  // 	components: [
  // 		tr4_rpm,
  // 		tr4_temp,
  // 		tr4_vibrationFront,
  // 		tr4_vibrationRear,
  // 		tr4_amp,
  // 		tr4_voltage,
  // 		tr4_time,
  // 	],
  // },
  // {
  // 	groupHeader: 'Trial Run Five ',
  // 	components: [
  // 		tr5_rpm,
  // 		tr5_temp,
  // 		tr5_vibrationFront,
  // 		tr5_vibrationRear,
  // 		tr5_amp,
  // 		tr5_voltage,
  // 		tr5_time,
  // 	],
  // },
  {
    groupHeader: "Temperature ",
    components: [stabilizedTemp, maxTemp],
  },
  {
    groupHeader: "Residual Imbalance ",
    components: [residualImbalanceFront, residualImbalanceRear],
  },
  {
    groupHeader: "Remark ",
    components: [remark],
  },
];
