import { useState } from "react";
import SettingsNav from "./SettingsNav";
import "./settings.css";
import ContactInformationSettings from "./ContactInformationSettings";
import ManageUsers from "./ManageUsers";

function Settings() {
  const [currentSetting, setCurrentSettings] = useState("contactInformation");

  const handleChangeSettingsMenu = (value) => {
    setCurrentSettings(value);
  };
  return (
    <div>
      <SettingsNav
        currentSetting={currentSetting}
        handleChangeSettingsMenu={handleChangeSettingsMenu}
      />

      {currentSetting === "contactInformation" && (
        <ContactInformationSettings />
      )}
      {currentSetting === "manageUsers" && <ManageUsers />}
    </div>
  );
}

export default Settings;
