import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import useHelperFunctions from "../../../helpers/functions";
import { TestingAndBalancingReport } from "../../../config/reports/TestingAndBalancingReport/TestingAndBalancingReport";
import { TableGroupHeader } from "../components/TableComponents";
import { Divider } from "@mui/material";
import FieldRenderer from "../components/FieldRender";
import TableComponent from "./TableComponent";

function FinalInspection() {
  const [value, setValue] = useState(0);
  const queryClient = useQueryClient(); // can you take this to helpers?

  const { location, currentOrder, isTabletOrBelow } = useHelperFunctions();

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const firstSegment = pathSegments[0];
  const secondSegment = pathSegments[1];
  const urlPrefix = `/report/${firstSegment}/${secondSegment}`;
  return (
    <div>
      <Divider />
      <TableGroupHeader>Trial Runs</TableGroupHeader>
      <TableComponent
        urlPrefix={urlPrefix}
        orderId={currentOrder.orderId}
        name={"runs"}
        queryClient={queryClient}
      />
      <Divider />
      {TestingAndBalancingReport.map((group) => {
        return (
          <div>
            <TableGroupHeader>{group.groupHeader}</TableGroupHeader>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: isTabletOrBelow ? "1fr" : "600px 600px",
                maxWidth: "1280px",
                columnGap: "48px",
              }}
            >
              {group.components.map((c) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "48px",
                    }}
                  >
                    <p
                      style={{
                        width: "500px",
                        fontSize: "18px",
                        marginRight: "8px",
                        color: "#616161",
                      }}
                    >
                      {c.label}
                    </p>
                    <div style={{ width: "500px" }}>
                      <FieldRenderer
                        field={c} // Pass the entire field object
                        value={value}
                        urlPrefix={urlPrefix}
                        orderId={currentOrder.orderId}
                        name={c.name}
                        queryClient={queryClient}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Divider />
          </div>
        );
      })}
    </div>
  );
}

export default FinalInspection;

/*
<div>
      {FinalInspectionReport.map((group) => {
        return (
          <div>
            <TableGroupHeader>{group.groupHeader}</TableGroupHeader>
            <TwoColumnTable>
              {group.components.map((c) => {
                return (
                  <TableCell width1={"300px"}>
                    <TableCellHeader1>{c.label}</TableCellHeader1>
                    <InputField
                      prefix={""}
                      suffix={c.unit}
                      value={value}
                      urlPrefix={urlPrefix}
                      orderId={currentOrder.orderId}
                      key={c.name}
                      name={c.name}
                      queryClient={queryClient}
                    />
                  </TableCell>
                );
              })}
            </TwoColumnTable>
          </div>
        );
      })}
    </div>
    */
