import React, { useState, useEffect } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Card, CardContent, Typography } from "@mui/material";

const quotes = [
  "Given enough eyeballs, all bugs are shallow. - Eric S. Raymond",
  "Programs must be written for people to read, and only incidentally for machines to execute. - Harold Abelson",
  "The best way to predict the future is to invent it. - Alan Kay",
  "First, solve the problem. Then, write the code. - John Johnson",
  "Simplicity is the soul of efficiency. - Austin Freeman",
  "Every great developer you know got there by solving problems they were unqualified to solve until they actually did it. - Patrick McKenzie",
  "Premature optimization is the root of all evil. - Donald Knuth",
  "Walking on water and developing software from a specification are easy if both are frozen. - Edward V. Berard",
  "If you automate a mess, you get an automated mess. - Rod Michael",
  "Software is like entropy: It is difficult to grasp, weighs nothing, and obeys the Second Law of Thermodynamics; i.e., it always increases. - Norman Augustine",
  "The function of good software is to make the complex appear to be simple. - Grady Booch",
  "If debugging is the process of removing bugs, then programming must be the process of putting them in. - Edsger Dijkstra",
  "The computer was born to solve problems that did not exist before. - Bill Gates",
  "A good programmer is someone who looks both ways before crossing a one-way street. - Doug Linder",
  "Code is like humor. When you have to explain it, it’s bad. - Cory House",
  "There are only two kinds of programming languages: those people always bitch about and those nobody uses. - Bjarne Stroustrup",
  "Measuring programming progress by lines of code is like measuring aircraft building progress by weight. - Bill Gates",
  "Before software can be reusable it first has to be usable. - Ralph Johnson",
  "Programming isn’t about what you know; it’s about what you can figure out. - Chris Pine",
  "Software undergoes beta testing shortly before it’s released. Beta is Latin for ‘still doesn’t work.’ - Anonymous",
  "The trouble with programmers is that you can never tell what a programmer is doing until it’s too late. - Seymour Cray",
  "The most disastrous thing that you can ever learn is your first programming language. - Alan Kay",
  "Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code. - Dan Salomon",
  "Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live. - John Woods",
  "The only way to learn a new programming language is by writing programs in it. - Dennis Ritchie",
  "A language that doesn’t affect the way you think about programming is not worth knowing. - Alan Perlis",
  "The sooner you start to code, the longer the program will take. - Roy Carlson",
  "Good programmers write code that humans can understand. - Martin Fowler",
  "Bad programmers worry about the code. Good programmers worry about data structures and their relationships. - Linus Torvalds",
  "Refactoring: changing the structure of code without changing its behavior. - Martin Fowler",
  "The best error message is the one that never shows up. - Thomas Fuchs",
  "If the code and the comments do not match, possibly both are incorrect. - Norm Schryer",
  "A program is never less than 90% complete, and never more than 95% complete. - Terry Baker",
  "Write code as if whoever maintains it is a violent sociopath who knows where you live. - John F. Woods",
  "The art of debugging is figuring out what you really told your program to do rather than what you thought you told it to do. - Andrew Singer",
  "There is no reason anyone would want a computer in their home. - Ken Olsen (1977)",
  "Real programmers can write assembly code in any language. - Larry Wall",
  "Software testing is a state of mind. - Anonymous",
  "It’s not a bug – it’s an undocumented feature. - Anonymous",
  "One man’s constant is another man’s variable. - Alan Perlis",
  "A good software developer is like a sorcerer, weaving logic into reality. - Anonymous",
  "An infinite loop is the best way to spend eternity. - Anonymous",
  "Code refactoring: because legacy code was once the future. - Anonymous",
  "The computer programmer is a creator of universes for which he alone is responsible. - Joseph Weizenbaum",
  "The best code is no code at all. - Jeff Atwood",
  "You should name a variable using the same care with which you name a first-born child. - Robert C. Martin",
  "Software engineering is the part of computer science which is too difficult for the computer scientist. - Friedrich Bauer",
  "The trick to being a great programmer is to never think of yourself as one. - Anonymous",
  "Debugging is twice as hard as writing the code in the first place. - Brian Kernighan",
  "The computer was born to solve problems that did not exist before. - Bill Gates",
  "A language that doesn’t change the way you think about programming is not worth knowing. - Alan Perlis",
  "Code is read much more often than it is written. - Guido van Rossum",
  "Simplicity is prerequisite for reliability. - Edsger Dijkstra",
  "The cheapest, fastest, and most reliable components are those that aren’t there. - Gordon Bell",
  "Perfection is achieved not when there is nothing more to add, but when there is nothing left to take away. - Antoine de Saint-Exupéry",
  "Most good programmers do programming not because they expect to get paid or get adulation by the public, but because it is fun to program. - Linus Torvalds",
  "Without requirements or design, programming is the art of adding bugs to an empty text file. - Louis Srygley",
  "In order to understand recursion, one must first understand recursion. - Anonymous",
  "A script is what you give the actors. A program is what you give the audience. - Larry Wall",
  "A good architecture is not about having the perfect design; it's about making trade-offs that allow progress. - Kent Beck",
  "The best thing about a boolean is even if you are wrong, you are only off by a bit. - Anonymous",
  "If you think it's simple, then you have misunderstood the problem. - Bjarne Stroustrup",
  "The best method for accelerating a computer is the one that boosts it by 9.8 m/s². - Anonymous",
  "There is always one more bug to fix. - Anonymous",
  "Controlling complexity is the essence of computer programming. - Brian Kernighan",
  "A computer lets you make more mistakes faster than any invention in human history, with the possible exceptions of handguns and tequila. - Mitch Ratcliffe",
  "When in doubt, use brute force. - Ken Thompson",
  "The most important property of a program is whether it accomplishes the intention of its user. - C.A.R. Hoare",
  "You can have the project done fast, cheap, or good. Pick two. - Anonymous",
  "The purpose of software engineering is to control complexity, not to create it. - Pamela Zave",
  "A real engineer builds bridges. A software engineer builds castles in the air. - Anonymous",
  "Programming without an overall architecture is like a string of pearls without a thread. - Anonymous",
  "Computers are incredibly fast, accurate, and stupid. Humans are incredibly slow, inaccurate, and brilliant. Together they are powerful beyond imagination. - Albert Einstein (attributed)",
  "There is no programming language, no matter how structured, that will prevent programmers from making bad programs. - Anonymous",
  "Always code as if the person who ends up maintaining your code is a psychopath who knows where you live. - Martin Golding",
  "The real problem is that programmers have spent far too much time worrying about efficiency in the wrong places and at the wrong times. - Donald Knuth",
  "The sooner you start to code, the longer the program will take. - Roy Carlson",
  "C makes it easy to shoot yourself in the foot; C++ makes it harder, but when you do, it blows your whole leg off. - Bjarne Stroustrup",
  "Python is an experiment in how much freedom programmers need. Too much freedom and nobody can read another’s code; too little and expressiveness is endangered. - Guido van Rossum",
  "It's hardware that makes a machine fast. It’s software that makes a fast machine slow. - Craig Bruce",
  "Simplicity is the ultimate sophistication. - Leonardo da Vinci",
  "You can't trust code that you did not totally create yourself. - Ken Thompson",
  "Computers themselves, and software yet to be developed, will revolutionize the way we learn. - Steve Jobs",
  "An algorithm must be seen to be believed. - Donald Knuth",
  "Every programmer is an author. - Sercan Leylek",
  "Simplicity does not precede complexity, but follows it. - Alan Perlis",
  "Your most unhappy customers are your greatest source of learning. - Bill Gates",
];

const RandomQuote = () => {
  const [quote, setQuote] = useState("");

  const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  };

  useEffect(() => {
    getRandomQuote();
  }, []);

  return (
    <Card
      sx={{
        maxWidth: 500,
        p: 3,
        marginLeft: "24px",
        mt: 4,
        boxShadow: 0,
        border: "1px solid lightgrey",
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {quote}
        </Typography>
        <Button
          onClick={getRandomQuote}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          <RefreshIcon />
        </Button>
      </CardContent>
    </Card>
  );
};

export default RandomQuote;
