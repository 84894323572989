import * as React from "react";
import { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { forms } from "../../config/order/order";
import useHelperFunctions from "../../helpers/functions";
import { useParams, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { useFetchData } from "../../api/axios";

function OrderPage() {
  const [different, setDifferent] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const {
    navigate: customNavigate,
    currentOrder,
    clearOrderIndex,
    setOrderIndex,
  } = useHelperFunctions();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const data = forms;

  // Fetch the order based on orderId
  const {
    data: newOrderData,
    isLoading,
    error,
  } = useFetchData({
    queryKey: ["order", orderId],
    url: `/order/${orderId}`, // Update this URL to match your API
    options: {
      enabled:
        !currentOrder ||
        !currentOrder.orderId ||
        currentOrder.orderId !== orderId,
    },
  });

  useEffect(() => {
    if (!currentOrder) {
      setLoadingMessage("Loading current order..."); // Show loading message if no current order
    }
  }, [currentOrder]);

  useEffect(() => {
    if (newOrderData && (!currentOrder || currentOrder.orderId !== orderId)) {
      // Clear current order and set new order data
      clearOrderIndex();
      setOrderIndex(newOrderData["data"]); // Assuming setOrderIndex updates currentOrder
      setDifferent(false); // Reset different to show timeline
      setOpenSnackbar(true); // Show notification
      setLoadingMessage(""); // Clear loading message once data is loaded
    }
  }, [newOrderData, orderId, currentOrder, clearOrderIndex, setOrderIndex]);

  useEffect(() => {
    if (!isLoading) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [isLoading, error, different]);

  // Show a notification when a new order is loaded
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Handle loading a new report
  function handleLoadReport(data) {
    customNavigate(data.url(currentOrder));
  }

  // Redirect to home if order not found
  const handleRedirectToHome = () => {
    navigate("/");
  };

  if (loadingMessage) {
    return <p>{loadingMessage}</p>;
  }

  if (isLoading || different) {
    return <p>Loading order...</p>;
  }

  // Conditional rendering based on loading, error, or different state
  if (isLoading || different) {
    return <p>Loading order...</p>;
  }

  if (error) {
    return (
      <div
        style={{
          margin: "64px",
          display: "flex",
          gap: "24px",
          flexDirection: "column",
          width: "360px",
        }}
      >
        <h2>Order Not Found</h2>
        <p>The requested order could not be found. Please try again.</p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRedirectToHome}
        >
          Go to Home
        </Button>
      </div>
    );
  }

  return (
    <>
      <VerticalTimeline>
        {data.map((report) => (
          <VerticalTimelineElement
            key={report.title}
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "white",
              color: "#333333",
              border: "1px solid lightgray",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #384B70" }}
            date={new Date("2024-07-13").toDateString()}
            iconStyle={{
              background: "white",
              color: "#fff",
              border: "1px solid #384B70",
              boxShadow: "none",
            }}
          >
            <h3 className="vertical-timeline-element-title">{report.label}</h3>
            <p>{report.description}</p>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLoadReport(report)}
              >
                Load Report
              </Button>
              {/* <Button variant='contained' color='success' disabled>
								Download Report
							</Button> */}
            </Box>
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement
          iconStyle={{ background: "#384B70", color: "#fff" }}
        />
      </VerticalTimeline>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="New order loaded"
      />
    </>
  );
}

export default OrderPage;
