// export const tr1_rpm = {
// 	name: 'tr1_rpm',
// 	label: 'Test Run 1 RPM',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr1_temp = {
// 	name: 'tr1_temp',
// 	label: 'Test Run 1 Temperature',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr1_vibrationFront = {
// 	name: 'tr1_vibrationFront',
// 	label: 'Test Run 1 Vibration Front',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr1_vibrationRear = {
// 	name: 'tr1_vibrationRear',
// 	label: 'Test Run 1 Vibration Rear',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr1_amp = {
// 	name: 'tr1_amp',
// 	label: 'Test Run 1 Amperage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr1_voltage = {
// 	name: 'tr1_voltage',
// 	label: 'Test Run 1 Voltage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr1_time = {
// 	name: 'tr1_time',
// 	label: 'Test Run 1 Time',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_rpm = {
// 	name: 'tr2_rpm',
// 	label: 'Test Run 2 RPM',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_temp = {
// 	name: 'tr2_temp',
// 	label: 'Test Run 2 Temperature',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_vibrationFront = {
// 	name: 'tr2_vibrationFront',
// 	label: 'Test Run 2 Vibration Front',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_vibrationRear = {
// 	name: 'tr2_vibrationRear',
// 	label: 'Test Run 2 Vibration Rear',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_amp = {
// 	name: 'tr2_amp',
// 	label: 'Test Run 2 Amperage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_voltage = {
// 	name: 'tr2_voltage',
// 	label: 'Test Run 2 Voltage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr2_time = {
// 	name: 'tr2_time',
// 	label: 'Test Run 2 Time',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_rpm = {
// 	name: 'tr3_rpm',
// 	label: 'Test Run 3 RPM',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_temp = {
// 	name: 'tr3_temp',
// 	label: 'Test Run 3 Temperature',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_vibrationFront = {
// 	name: 'tr3_vibrationFront',
// 	label: 'Test Run 3 Vibration Front',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_vibrationRear = {
// 	name: 'tr3_vibrationRear',
// 	label: 'Test Run 3 Vibration Rear',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_amp = {
// 	name: 'tr3_amp',
// 	label: 'Test Run 3 Amperage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_voltage = {
// 	name: 'tr3_voltage',
// 	label: 'Test Run 3 Voltage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr3_time = {
// 	name: 'tr3_time',
// 	label: 'Test Run 3 Time',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_rpm = {
// 	name: 'tr4_rpm',
// 	label: 'Test Run 4 RPM',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_temp = {
// 	name: 'tr4_temp',
// 	label: 'Test Run 4 Temperature',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_vibrationFront = {
// 	name: 'tr4_vibrationFront',
// 	label: 'Test Run 4 Vibration Front',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_vibrationRear = {
// 	name: 'tr4_vibrationRear',
// 	label: 'Test Run 4 Vibration Rear',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_amp = {
// 	name: 'tr4_amp',
// 	label: 'Test Run 4 Amperage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_voltage = {
// 	name: 'tr4_voltage',
// 	label: 'Test Run 4 Voltage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr4_time = {
// 	name: 'tr4_time',
// 	label: 'Test Run 4 Time',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_rpm = {
// 	name: 'tr5_rpm',
// 	label: 'Test Run 5 RPM',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_temp = {
// 	name: 'tr5_temp',
// 	label: 'Test Run 5 Temperature',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_vibrationFront = {
// 	name: 'tr5_vibrationFront',
// 	label: 'Test Run 5 Vibration Front',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_vibrationRear = {
// 	name: 'tr5_vibrationRear',
// 	label: 'Test Run 5 Vibration Rear',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_amp = {
// 	name: 'tr5_amp',
// 	label: 'Test Run 5 Amperage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_voltage = {
// 	name: 'tr5_voltage',
// 	label: 'Test Run 5 Voltage',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

// export const tr5_time = {
// 	name: 'tr5_time',
// 	label: 'Test Run 5 Time',
// 	type: 'text',
// 	validation: {
// 		type: 'string',
// 		required: true,
// 	},
// };

export const stabilizedTemp = {
  name: "stabilizedTemp",
  label: "Stabilized Temperature",
  type: "text",
  validation: {
    type: "string",
    required: true,
  },
};

export const maxTemp = {
  name: "maxTemp",
  label: "Max Temperature",
  type: "text",
  validation: {
    type: "string",
    required: true,
  },
};

export const residualImbalanceFront = {
  name: "residualImbalanceFront",
  label: "Residual Imbalance Front",
  type: "text",
  validation: {
    type: "string",
    required: true,
  },
};

export const residualImbalanceRear = {
  name: "residualImbalanceRear",
  label: "Residual Imbalance Rear",
  type: "text",
  validation: {
    type: "string",
    required: true,
  },
};

export const remark = {
  name: "remark",
  label: "Remark",
  type: "longtext",
  validation: {
    type: "string",
    optional: true,
    maxLength: 1000,
  },
};

export const TestingBalancingComponents = {
  // tr1_rpm,
  // tr1_temp,
  // tr1_vibrationFront,
  // tr1_vibrationRear,
  // tr1_amp,
  // tr1_voltage,
  // tr1_time,
  // tr2_rpm,
  // tr2_temp,
  // tr2_vibrationFront,
  // tr2_vibrationRear,
  // tr2_amp,
  // tr2_voltage,
  // tr2_time,
  // tr3_rpm,
  // tr3_temp,
  // tr3_vibrationFront,
  // tr3_vibrationRear,
  // tr3_amp,
  // tr3_voltage,
  // tr3_time,
  // tr4_rpm,
  // tr4_temp,
  // tr4_vibrationFront,
  // tr4_vibrationRear,
  // tr4_amp,
  // tr4_voltage,
  // tr4_time,
  // tr5_rpm,
  // tr5_temp,
  // tr5_vibrationFront,
  // tr5_vibrationRear,
  // tr5_amp,
  // tr5_voltage,
  // tr5_time,
  stabilizedTemp,
  maxTemp,
  residualImbalanceFront,
  residualImbalanceRear,
  remark,
};
