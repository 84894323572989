import React from "react";
import RandomQuote from "./RandomQuotes";
import { Typography } from "@mui/material";

function ReportIssues() {
  return (
    <>
      <div
        style={{
          fontSize: "18px",
          maxWidth: "600px",
          lineHeight: "27px",
          margin: "32px 24px 0 24px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontStyle: "italic", marginBottom: "24px" }}
          color="primary"
        >
          Given enough eyeballs, all bugs are shallow.
        </Typography>
        <Typography variant="h6">
          Developing an application is a collaborative effort. Feel free to
          reach out at <strong>sourabh.raghavendra@gmail.com</strong> or{" "}
          <strong>+91 7972616845</strong> if you find a scope for an improvement
          no matter how small.{" "}
        </Typography>
      </div>
      <RandomQuote />
    </>
  );
}

export default ReportIssues;
