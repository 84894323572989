import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useHelperFunctions from "../../helpers/functions";

const ProtectedRoute = ({ children, allowedUsers, notAllowed }) => {
  const { isUser, verifyUser, userRole, navigate } = useHelperFunctions();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    verifyUser();
    setIsLoading(false); // Mark loading as complete
  }, [isUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isUser) {
    return <Navigate to="/login" />;
  }

  // if (userRole !== "customer") {
  //   navigate("/me");
  // }

  if (!allowedUsers.includes(userRole)) {
    return (
      <div>
        You do not have access to this resource. If you think it is a mistake on
        our part, please attach the screenshot and email us along with the page
        url.
      </div>
    );
  }

  return <div>{children}</div>;
};

export default ProtectedRoute;
