import { Controller, useForm } from "react-hook-form";
import { useMutateData } from "../../api/axios";
import { Button, Input, MenuItem, Select, Typography } from "@mui/material";
import FieldContainer from "../shared/form/dynamic/components/FieldContainer";
import FiledLabel from "../shared/form/dynamic/components/FiledLabel";
import { useQueryClient } from "@tanstack/react-query";
import useGetAllEmployees from "../../api/hooks/useGetAllEmployees";

const options = [
  {
    value: "",
    label: "select an option",
  },
  {
    value: "manager",
    label: "Manager",
  },
  {
    value: "supervisor",
    label: "Supervisor",
  },
  {
    value: "technician",
    label: "Technician",
  },
];

const AddEmployeeForm = () => {
  const { register, handleSubmit, control, formData, reset } = useForm();
  const queryClient = useQueryClient();

  const [
    allEmployeesData,
    loadingAllEmployeesData,
    errorLoadingAllEmployeesData,
    loadedAllEmployeesData,
    refetchAllEmployees,
  ] = useGetAllEmployees();

  const { mutate, isLoading, isError, isSuccess, error } = useMutateData({
    method: "POST",
    url: "/auth/add-employee",
  });

  const onSubmit = (data) => {
    if (data.userRole === "") {
      alert("Select user role");
      return false;
    }
    console.log(data);
    mutate(data, {
      onSuccess: (response) => {
        console.log("Customer successfully added!");
        console.log("Backend Response:", response.data);
        queryClient.invalidateQueries({ queryKey: ["all-employees"] });
        refetchAllEmployees();
        reset();
      },
      onError: (error) => {
        console.error(
          "Error adding customer:",
          error.response?.data || error.message
        );
      },
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: "600px", padding: "24px 0" }}
    >
      {isError && (
        <Typography gutterBottom color="error" mb={2}>
          Email already registered
        </Typography>
      )}
      <FieldContainer>
        <FiledLabel>Email</FiledLabel>
        <Input {...register("email", { required: true })} />
      </FieldContainer>
      <FieldContainer>
        <FiledLabel>Role</FiledLabel>
        <Controller
          name="userRole"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select {...field} displayEmpty>
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.value === ""}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FieldContainer>
      <Button type="submit" variant="outlined">
        Submit
      </Button>
    </form>
  );
};

export default AddEmployeeForm;
