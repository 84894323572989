import React, { useState } from "react";
import useCustomerContact from "../../api/hooks/useCustomerContact";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Typography,
  Box,
} from "@mui/material";

function CustomerContactsTable() {
  const [
    customerContactData,
    loadingCustomerContact,
    errorLoadingCustomerContact,
    loadedCustomerContact,
  ] = useCustomerContact("all");

  if (!loadedCustomerContact || errorLoadingCustomerContact) {
    return <p>Something went wrong while loading the customer data</p>;
  }

  if (loadingCustomerContact) {
    return <p>Loading customer contact database</p>;
  }

  return (
    <div>
      <CustomerTable data={customerContactData?.data} />
    </div>
  );
}

export default CustomerContactsTable;

const CustomerTable = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handlePageChange = (_event, newPage) => {
    setPage(newPage);
  };

  const handleCustomerClick = (customerName) => {
    console.log("Customer Name:", customerName);
  };

  const filteredData = data?.filter((item) =>
    item.customerId.customerName.toLowerCase().includes(searchTerm)
  );

  return (
    <Box mt={3} sx={{ padding: "0 32px", maxWidth: "1080px" }}>
      {/* Search Input */}
      <TextField
        label={`Search amongst ${data.length} customer contact persons`}
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Sr</strong>
              </TableCell>
              <TableCell>
                <strong>User Email</strong>
              </TableCell>
              <TableCell>
                <strong>Customer Name</strong>
              </TableCell>
              <TableCell>
                <strong>Assigned Orders Count</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>
                    {item.userId ? item.userId.email : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        handleCustomerClick(item.customerId.customerName)
                      }
                    >
                      {item.customerId.customerName}
                    </Typography>
                  </TableCell>
                  <TableCell>{item.assignedOrders.length}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={filteredData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
      />
    </Box>
  );
};
