import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Input,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import useCustomerData from "../../api/hooks/useCustomerData";
import useCustomerContact from "../../api/hooks/useCustomerContact";
import { useMutateData } from "../../api/axios";
import { useQueryClient } from "@tanstack/react-query";

function SelectCustomer({
  selectedCustomer,
  setSelectedCustomer,
  setModal,
  setSelectedCustomerContactEmail,
  setContactPersonId,
}) {
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);

  console.log("selected customer: ", selectedCustomer);

  return (
    <Dialog
      open={true}
      // onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
      sx={{
        maxHeight: "680px",
        position: "absolute",
        top: "150px",
        left: "100px",
      }}
    >
      <Button onClick={() => setModal(false)}>Close</Button>
      <DialogTitle>Customer Details</DialogTitle>
      {isCustomerSelected ? (
        <SelectedCustomer
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setIsCustomerSelected={setIsCustomerSelected}
          setSelectedCustomerContactEmail={setSelectedCustomerContactEmail}
          setContactPersonId={setContactPersonId}
          setModal={setModal}
        />
      ) : (
        <CustomerList
          setSelectedCustomer={setSelectedCustomer}
          setIsCustomerSelected={setIsCustomerSelected}
        />
      )}
    </Dialog>
  );
}

export default SelectCustomer;

function CustomerList({ setSelectedCustomer, setIsCustomerSelected }) {
  const [
    customerData,
    loadingCustomerData,
    errorLoadingCustomer,
    loadedCustomer,
  ] = useCustomerData();

  const handleOnCustomerClick = (d) => {
    setSelectedCustomer(d);
    setIsCustomerSelected(true);
  };

  return (
    <Box
      sx={{
        marginBottom: "16px",
      }}
    >
      {loadingCustomerData ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            p: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <CustomerDataTable
          customerData={customerData}
          handleOnCustomerClick={handleOnCustomerClick}
        />
      )}
    </Box>
  );
}

function CustomerDataTable({ customerData, handleOnCustomerClick }) {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter customers based on ID, Name, or City
  const filteredCustomers = customerData?.data?.filter(
    (d) =>
      d.customerId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      d.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      d.customerCity.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      {/* Search Bar */}
      <Box sx={{ px: 2 }}>
        <TextField
          label="Search by ID, Name, or City"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />
      </Box>
      <Typography variant="body2" sx={{ px: 2, mb: 2 }}>
        {`Found ${filteredCustomers.length} customer(s)`}
      </Typography>

      {/* Customer List */}
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          borderBottom: "1px solid #f5f5f5",
          padding: 2,
          alignItems: "center",
          cursor: "pointer",
          bgcolor: "#f5f5f5",
        }}
      >
        <Typography>Customer Id</Typography>
        <Typography sx={{ width: "240px" }}>Customer Name</Typography>
        <Typography>Customer City</Typography>
      </Box>
      <Box
        sx={{
          padding: 2,
          minHeight: "260px",
          maxHeight: "260px",
          overflow: "auto",
        }}
      >
        {filteredCustomers?.length > 0 ? (
          filteredCustomers.map((d) => (
            <Box
              key={d.customerId} // Added key for React optimization
              onClick={() => handleOnCustomerClick(d)}
              sx={{
                display: "flex",
                gap: "24px",
                borderBottom: "1px solid #f5f5f5",
                // marginBottom: "12px",
                padding: "12px 0",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": { backgroundColor: "#f9f9f9" }, // Hover effect
              }}
            >
              <Typography>{d.customerId}</Typography>
              <Typography sx={{ width: "240px" }}>{d.customerName}</Typography>
              <Typography>{d.customerCity}</Typography>
            </Box>
          ))
        ) : (
          <Typography color="textSecondary" sx={{ textAlign: "center", mt: 2 }}>
            No customers found
          </Typography>
        )}
      </Box>
    </div>
  );
}

function SelectedCustomer({
  selectedCustomer,
  setSelectedCustomer,
  setIsCustomerSelected,
  setSelectedCustomerContactEmail,
  setContactPersonId,
  setModal,
}) {
  const handleChangeCustomer = () => {
    setSelectedCustomer(null);
    setSelectedCustomerContactEmail(null);
    setIsCustomerSelected(false);
  };
  console.log(selectedCustomer);
  return (
    <Box
      sx={{
        minHeight: "360px",
        maxHeight: "360px",
        p: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body" gutterBottom>
          <strong>Customer Id</strong> : {selectedCustomer.customerId}
        </Typography>
        <Typography variant="body" gutterBottom>
          <strong>Customer name</strong> : {selectedCustomer.customerName}
        </Typography>
        <Typography variant="body" gutterBottom>
          <strong>Customer location</strong> : {selectedCustomer.customerCity}
        </Typography>
      </Box>

      <CustomerContact
        customerId={selectedCustomer._id}
        setSelectedCustomerContactEmail={setSelectedCustomerContactEmail}
        setContactPersonId={setContactPersonId}
        setModal={setModal}
      />
      <Box>
        <Button
          onClick={handleChangeCustomer}
          color="warning"
          variant="outlined"
        >
          Change customer
        </Button>
        {/* <Button onClick={handleChangeCustomer} variant="outlined">
          Add New Contact
        </Button> */}
      </Box>
    </Box>
  );
}

function CustomerContact({
  customerId,
  setSelectedCustomerContactEmail,
  setContactPersonId,
  setModal,
}) {
  const [openAddCustomerContactForm, setOpenAddCustomerContactForm] =
    useState(false);
  const [customerEmail, setCustomerEmail] = useState("");

  const queryClient = useQueryClient();

  const [
    customerContactData,
    loadingCustomerContact,
    errorLoadingCustomerContact,
    loadedCustomerContact,
  ] = useCustomerContact(customerId);

  if (customerContactData) {
    console.log(customerContactData);
  }

  const { mutate } = useMutateData({
    method: "POST",
    url: `/customer/add-customer-contact`,
  });

  function handleAddCustomerContactSubmit() {
    setOpenAddCustomerContactForm(true);
  }

  function handleCustomerEmailChange(e) {
    setCustomerEmail(e.target.value);
  }

  function handleSelectCustomerEmail(p) {
    setSelectedCustomerContactEmail(p.userId.email);
    setContactPersonId(p._id);
    setModal(false);
  }

  function handleCustomerContactSubmit(e) {
    const data = { customerId, customerContactEmail: customerEmail };
    mutate(data, {
      onSuccess: (data) => {
        queryClient.invalidateQueries([`customerContact-${customerId}`]);
        console.log("customer contact added", data.data);
      },
      onError: (error) => {
        console.error("Error creating order:", error);
      },
    });
    setOpenAddCustomerContactForm(false);
  }

  return (
    <Box sx={{ mb: 2, mt: 2 }}>
      {customerContactData &&
        customerContactData.data.length === 0 &&
        "No contact persons found"}
      {customerContactData && customerContactData.data.length > 0 && (
        <Box sx={{ display: "flex", gap: "16px" }}>
          {customerContactData.data.map((p, index) => (
            <Typography
              onClick={() => handleSelectCustomerEmail(p)}
              key={index}
              sx={{
                p: 1,
                border: "1px solid grey",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "grey",
                  color: "white",
                  cursor: "pointer",
                },
              }}
            >
              {p.userId?.email ? p?.userId?.email : ""}
            </Typography>
          ))}
        </Box>
      )}

      <Box sx={{ my: 2 }}>
        <Button
          variant="outlined"
          onClick={() => handleAddCustomerContactSubmit()}
        >
          Add New Contact Person
        </Button>
      </Box>

      {openAddCustomerContactForm && (
        <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
          Create a new customer contact person
          <Box sx={{ display: "flex", my: 1 }}>
            <Input onChange={handleCustomerEmailChange} />
            <Button onClick={handleCustomerContactSubmit}>Submit</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
