import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useFetchData, useMutateData } from "../../api/axios";
import { useQueryClient } from "@tanstack/react-query";

export default function CustomerModal({ selectedCustomer, handleCloseModal }) {
  const [editableField, setEditableField] = useState(null); // Track the field being edited
  const [updatedData, setUpdatedData] = useState({}); // Store updated data locally

  const queryClient = useQueryClient();

  const { data: customerData } = useFetchData({
    queryKey: [selectedCustomer._id],
    url: `/customer/${selectedCustomer._id}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  const { mutate: updateCustomer } = useMutateData({
    method: "PATCH",
    url: `/customer/${selectedCustomer._id}`,
  });

  const handleEditClick = (field) => {
    setEditableField(field);
    setUpdatedData((prev) => ({
      ...prev,
      [field]: customerData?.data?.[field] || "Not Updated",
    }));
  };

  const handleSaveClick = () => {
    updateCustomer(updatedData, {
      onSuccess: (data) => {
        queryClient.invalidateQueries([selectedCustomer._id]);
        console.log("Order created successfully:", data.data);
        setEditableField(null);
      },
      onError: (error) => {
        console.error(
          "Error updating customer:",
          error.response?.data || error.message
        );
      },
    });
  };

  const handleFieldChange = (field, value) => {
    setUpdatedData((prev) => ({ ...prev, [field]: value }));
  };

  const renderField = (field, label) => {
    const isEditing = editableField === field;
    const value =
      customerData?.data?.[field] !== undefined &&
      customerData?.data?.[field] !== ""
        ? customerData.data[field]
        : "Not Updated";

    return (
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <IconButton
          onClick={() =>
            isEditing ? handleSaveClick() : handleEditClick(field)
          }
        >
          {isEditing ? <SaveIcon color="primary" /> : <EditIcon />}
        </IconButton>
        <Typography variant="subtitle1">
          <strong>{label}:</strong>
        </Typography>

        {isEditing ? (
          <TextField
            fullWidth
            value={updatedData[field] || ""}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            autoFocus
          />
        ) : (
          <Typography variant="body1">{value}</Typography>
        )}
      </div>
    );
  };

  if (!customerData) return null;

  return (
    <Dialog
      open={!!selectedCustomer}
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Customer Details</DialogTitle>
      <DialogContent>
        {/* {renderField("customerId", "Customer ID")} */}
        <div
          style={{
            color: "gray",
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <p>Customer Id: {customerData?.data?.["customerId"]}</p>
          <p>Customer Name: {customerData?.data?.["customerName"]}</p>
        </div>
        {/* {renderField("customerName", "Customer Name")} */}
        {renderField("customerCity", "Customer City")}
        {renderField("customerState", "Customer State")}
        {renderField("customerCountry", "Customer Country")}
        {renderField("postalCode", "Postal Code")}
        {/* {renderField("customerContactEmail", "Contact Email")}
        {renderField("customerConactPerson", "Contact Person")}
        {renderField("customerConactPhone", "Contact Phone")} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
