import { Box, Button, Container, Select, Typography } from "@mui/material";
import SectionHeader from "../../../shared/typography/SectionHeader";
import SectionSubHeader from "../../../shared/typography/SectionSubHeader";
import InputInward from "./InputInward";
import useHelperFunctions from "../../../../helpers/functions";
import { useState } from "react";
import SelectCustomer from "../../../customer/SelectCustomer";

function CreateOrder() {
  const [modal, setModal] = useState(false);

  //
  const [selectedCustomerContactEmail, setSelectedCustomerContactEmail] =
    useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [contactPersonId, setContactPersonId] = useState(null);
  //

  const {
    createdNewOrder,
    currentOrder,
    navigate,
    clearOrderIndex,
    hideOutlet,
    setCreatedNewOrderFalse,
  } = useHelperFunctions();

  function handleChangeOrder() {
    clearOrderIndex();
    hideOutlet();
    setCreatedNewOrderFalse();
    navigate("/all-orders");
  }

  return (
    <Container disableGutters>
      {!createdNewOrder ? (
        <SectionHeader>Create New Order</SectionHeader>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SectionHeader>
            {currentOrder
              ? `You are currently editing ${currentOrder.inwardNumber}`
              : "Something went wrong, please crate the order again."}
          </SectionHeader>
          <div style={{ display: "flex", gap: "16px", marginTop: "24px" }}>
            <Button variant="contained">
              {" "}
              <a
                href={currentOrder ? currentOrder.qrCodeUrl : ""}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Download QR{" "}
              </a>{" "}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(`/${currentOrder["orderId"]}`)}
            >
              Update Reports{" "}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleChangeOrder}
            >
              Change Order{" "}
            </Button>
          </div>
        </div>
      )}

      {!createdNewOrder && (
        <div className="formContainer">
          <SectionSubHeader>Enter Inward Number</SectionSubHeader>
          <InputInward
            customer={selectedCustomer}
            setModal={setModal}
            selectedCustomerContactEmail={selectedCustomerContactEmail}
            contactPersonId={contactPersonId}
            setSelectedCustomerContactEmail={setSelectedCustomerContactEmail}
            setSelectedCustomer={setSelectedCustomer}
          />
        </div>
      )}

      {modal && (
        <SelectCustomer
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setModal={setModal}
          setSelectedCustomerContactEmail={setSelectedCustomerContactEmail}
          setContactPersonId={setContactPersonId}
        />
      )}
    </Container>
  );
}

export default CreateOrder;

/* {modal && customer !== null && (
        <CustomerModal
          selectedCustomer={customer}
          handleCloseModal={handleCloseModal}
        />
      )} */
