import React, { useState, useEffect } from "react";
import { Select, MenuItem, InputAdornment, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Success feedback icon
import { useFetchData, useMutateData } from "../../../api/axios";

const DropdownField = ({
  value: initialValue,
  options = [],
  name,
  orderId,
  urlPrefix,
  queryClient,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [showSuccess, setShowSuccess] = useState(false);

  // Fetch existing field data
  const { data, error, isLoading } = useFetchData({
    queryKey: [name],
    url: `${urlPrefix}/field?field=${encodeURIComponent(
      name
    )}&orderId=${encodeURIComponent(orderId)}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  // ✅ Update value when API data is fetched, only if user hasn't changed it
  useEffect(() => {
    if (data?.data !== undefined && value === initialValue) {
      setValue(data.data);
    }
  }, [data]);

  // Mutation to save the selected value
  const { mutate, error: mutationError } = useMutateData({
    method: "PATCH",
    url: `${urlPrefix}`,
  });

  const handleSave = () => {
    if (!isEditable) return;
    const payload = {
      field: name,
      value: value,
      orderId: orderId,
    };

    console.log("Saving:", payload);

    mutate(payload, {
      onSuccess: () => {
        console.log("Save Success");
        queryClient.invalidateQueries([name]); // Refetch query
        setIsEditable(false);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1000);
      },
      onError: (error) => {
        console.error("Save Error:", error);
      },
    });
  };

  return (
    <Select
      variant="outlined"
      fullWidth
      value={value} // ✅ Now properly updates value
      onChange={(e) => {
        setValue(e.target.value); // ✅ Ensure UI updates
        setIsEditable(true);
      }}
      onBlur={handleSave} // ✅ Save when focus is lost
      sx={{
        cursor: "pointer",
        backgroundColor: isEditable ? "#f0f0f0" : "white",
        "& .MuiSelect-select": {
          color: "#384B70",
          cursor: isEditable ? "text" : "pointer",
        },
      }}
      error={Boolean(error || mutationError)}
      startAdornment={
        <>
          {isEditable && (
            <IconButton onClick={handleSave}>
              <SaveIcon color="primary" />
            </IconButton>
          )}
          {!isEditable && showSuccess && (
            <IconButton disabled>
              <CheckCircleIcon color="success" />
            </IconButton>
          )}
        </>
      }
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DropdownField;
