import React from "react";

const options = [
  {
    label: "Contact Information",
    name: "contactInformation",
  },
  {
    label: "Manage Users",
    name: "manageUsers",
  },
  {
    label: "Add new bearing type",
    name: "addNewBearingType",
  },
];

function SettingsNav({ handleChangeSettingsMenu, currentSetting }) {
  return (
    <div className="settingsNav">
      {options.map((o) => {
        return (
          <p
            key={o.name}
            className={
              currentSetting === o.name ? "activeSetting" : "settingOption"
            }
            onClick={() => handleChangeSettingsMenu(o.name)}
          >
            {o.label}
          </p>
        );
      })}
    </div>
  );
}

export default SettingsNav;
