import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const useUIStore = create(
  devtools(
    persist((set) => ({
      // Initial state
      isUser: false,
      userRole: "",
      isSidebarOpen: false,
      isOutletOpen: false,
      createdNewOrder: false,
      orderIndex: {},

      // Actions to update state
      toggleSidebar: () =>
        set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
      toggleOutlet: () =>
        set((state) => ({ isOutletOpen: !state.isOutletOpen })),

      showOutlet: () => set({ isOutletOpen: true }),
      hideOutlet: () => set({ isOutletOpen: false }),

      showSidebar: () => set({ isSidebarOpen: true }),
      hideSidebar: () => set({ isSidebarOpen: false }),

      setCreatedNewOrderTrue: () => set({ createdNewOrder: true }),
      setCreatedNewOrderFalse: () => set({ createdNewOrder: false }),

      setOrderIndex: (orderData) => set({ orderIndex: orderData }),
      clearOrderIndex: () => {
        set({ orderIndex: {} });
      },

      verifyUser: () => {
        const token = localStorage.getItem("at");

        if (!token) {
          console.log("Forbidden");
          set(() => ({
            isUser: false,
            userRole: "",
          }));
          return;
        }

        try {
          const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT
          const isValid = payload.exp > Date.now() / 1000; // Check expiry

          if (isValid) {
            set((state) => {
              if (!state.isUser) {
                return {
                  isUser: true,
                  userRole: payload.role,
                };
              }
              return state; // Do not update if already verified
            });
          } else {
            console.log("Session expired. Login again");
            localStorage.removeItem("at"); // Remove invalid token
            set(() => ({
              isUser: false,
              userRole: "",
            }));
          }
        } catch (err) {
          console.error("Token verification failed:", err.message);
          localStorage.removeItem("at"); // Remove invalid token
          set(() => ({
            isUser: false,
            userRole: "",
          }));
        }
      },
      getUserId: () => {
        const token = localStorage.getItem("at");

        if (!token) {
          console.log("Forbidden");
          set(() => ({
            isUser: false,
            userRole: "",
          }));
          return;
        }

        try {
          const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT
          const isValid = payload.exp > Date.now() / 1000; // Check expiry

          if (isValid) {
            return payload.username;
          } else {
            console.log("Session expired. Login again");
            localStorage.removeItem("at"); // Remove invalid token
            set(() => ({
              isUser: false,
              userRole: "",
            }));
          }
        } catch (err) {
          console.error("Token verification failed:", err.message);
          localStorage.removeItem("at"); // Remove invalid token
          set(() => ({
            isUser: false,
            userRole: "",
          }));
        }
      },
      removeUser: () => {
        set({ isUser: false, userRole: "" });
      },
    }))
  )
);

export default useUIStore;
