import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useHelperFunctions from "../../helpers/functions";
import useAssignedOrders from "../../api/hooks/useAssignedOrders";

function Me() {
  const { userRole, getUserId } = useHelperFunctions();
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(getUserId());
  }, [userRole]);

  const [assignedOrders, loadingAssignedOrders, errorInAssignedOrders] =
    useAssignedOrders(email);

  const navigate = useNavigate();

  if (loadingAssignedOrders) return <Typography>Loading...</Typography>;
  if (errorInAssignedOrders)
    return <Typography color="error">Error fetching orders</Typography>;

  return (
    <Grid container spacing={2} justifyContent="center">
      {assignedOrders?.data?.map((order) => (
        <Grid item key={order._id}>
          <Card
            sx={{
              width: "200px", // Fixed width for the card
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 1,
              borderRadius: "8px",
            }}
            onClick={() => navigate(`/${order._id}`)}
          >
            <Box
              sx={{
                width: "100%",
                height: "200px", // Fixed height to maintain 1:1 aspect ratio
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Ensures full visibility of the image
                }}
                image={order.qrCodeUrl}
                alt={`QR Code for Order ${order.inwardNumber}`}
              />
            </Box>
            <CardContent>
              <Typography variant="h6" align="center">
                {order.inwardNumber}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Me;
