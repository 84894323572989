import { Typography } from "@mui/material";

export function Table({ children }) {
  return <div className="table">{children}</div>;
}

export function TableGroupHeader({ children }) {
  return <div className="tableGroupHeader">‣ {children}</div>;
}

export function TableRow({ children }) {
  return <div className="tableRow">{children}</div>;
}

export function TableCell({ children, width1 }) {
  return (
    <div className="tableCell" style={{ width: `${width1}` }}>
      {children}
    </div>
  );
}

export function TableCellHeader({ children }) {
  return (
    <Typography variant="h6" color="primary">
      {children}
    </Typography>
  );
}

export function TableCellHeader1({ children }) {
  return (
    <Typography variant="h6" color="primary">
      {children}
    </Typography>
  );
}

export function TwoColumnTable({ children }) {
  return <div className="twoColumnTable">{children}</div>;
}
