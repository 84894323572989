import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useHelperFunctions from "../../../helpers/functions";

const OptionsList = ({ menuItems }) => {
  const [expandedMenu, setExpandedMenu] = useState(null);

  const { navigate, showOutlet, hideOutlet, hideSidebar } =
    useHelperFunctions();

  const handleClick = (url) => {
    // const hideOutletFor = ['/all-orders'];
    navigate(url);
    hideSidebar();
    hideOutlet();
  };

  const handleExpand = (label) => {
    if (expandedMenu === label) {
      setExpandedMenu(null); // Close the menu if it's already expanded
    } else {
      setExpandedMenu(label);
    }
  };

  return (
    <List className="optionsList">
      {menuItems.map((menuItem, index) => (
        <div key={index}>
          {/* Render top-level menu items */}
          {menuItem.children ? (
            <>
              <ListItem
                onClick={() => handleExpand(menuItem.label)}
                sx={{ cursor: "pointer", height: "60px" }}
              >
                {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                <ListItemText primary={menuItem.label} />
                <IconButton edge="end">
                  {expandedMenu === menuItem.label ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </ListItem>

              {/* Render children as collapsible items */}
              <Collapse
                in={expandedMenu === menuItem.label}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {menuItem.children.map((child, childIndex) => (
                    <ListItem
                      key={childIndex}
                      component="a"
                      sx={{ cursor: "pointer", pl: 4 }}
                      onClick={() => handleClick(child.path)}
                    >
                      {child.icon && <ListItemIcon>{child.icon}</ListItemIcon>}
                      <ListItemText primary={child.label} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <Divider />
            </>
          ) : (
            <>
              <ListItem
                component="a"
                onClick={() => handleClick(menuItem.path)}
                sx={{ height: "60px", cursor: "pointer" }}
              >
                {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                <ListItemText primary={menuItem.label} />
              </ListItem>
              <Divider />
            </>
          )}
        </div>
      ))}
    </List>
  );
};

export default OptionsList;
