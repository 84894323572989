import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useFetchData, useMutateData } from "../../../api/axios";

const LongTextField = ({
  value: initialValue,
  name,
  orderId,
  urlPrefix,
  queryClient,
  minRows = 3,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [showSuccess, setShowSuccess] = useState(false);

  // Fetch existing field data
  const { data, error, isLoading } = useFetchData({
    queryKey: [name],
    url: `${urlPrefix}/field?field=${encodeURIComponent(
      name
    )}&orderId=${encodeURIComponent(orderId)}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  useEffect(() => {
    if (data?.data !== undefined && value === initialValue) {
      setValue(data.data);
    }
  }, [data]);

  // Mutation to save the input text
  const { mutate, error: mutationError } = useMutateData({
    method: "PATCH",
    url: `${urlPrefix}`,
  });

  const handleSave = () => {
    if (!isEditable) return;
    const payload = {
      field: name,
      value: value,
      orderId: orderId,
    };

    mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries([name]);
        setIsEditable(false);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1000);
      },
      onError: (error) => {
        console.error("Save Error:", error);
      },
    });
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      multiline
      minRows={minRows}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        setIsEditable(true);
      }}
      onBlur={handleSave}
      error={Boolean(error || mutationError)}
      InputProps={{
        startAdornment: (
          <>
            {isEditable && (
              <InputAdornment position="start">
                <IconButton onClick={handleSave}>
                  <SaveIcon color="primary" />
                </IconButton>
              </InputAdornment>
            )}
            {!isEditable && showSuccess && (
              <InputAdornment position="start">
                <IconButton disabled>
                  <CheckCircleIcon color="success" />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
        sx: {
          backgroundColor: isEditable ? "#f9f9f9" : "white",
        },
      }}
    />
  );
};

export default LongTextField;
