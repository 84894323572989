import React from "react";
import { useFetchData } from "../../../api/axios";
import { Typography } from "@mui/material";

function RemarksBox({ currentReport, currentReportId, currentOrder }) {
  return (
    <GetRemarks
      currentReport={currentReport}
      currentReportId={currentReportId}
      orderId={currentOrder["orderId"]}
    />
  );
}

export default RemarksBox;

function GetRemarks({ currentReport, currentReportId, orderId }) {
  const { data, error } = useFetchData({
    queryKey: ["order-remarks"],
    url: `report/${orderId}/${currentReport}/${currentReportId}/get-remarks`,
  });

  console.log(data ? data : "waiting for data");
  return (
    <div className="notesContainer">
      {data?.data && data?.data.length > 0
        ? [...data.data].reverse().map((u, index) => (
            <div className="note" key={index}>
              <Typography variant="body" color="textPrimary">
                {u}
              </Typography>
            </div>
          ))
        : ""}
    </div>
  );
}
