import { useFetchData } from "../axios";

function useCustomerContact(customerId) {
  const {
    data: customerContactData,
    isLoading: loadingCustomerContact,
    isError: errorLoadingCustomerContact,
    isSuccess: loadedCustomerContact,
  } = useFetchData({
    queryKey: [`customerContact-${customerId}`],
    url: `/customer/get-customer-contact?customerId=${encodeURIComponent(
      customerId
    )}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });
  return [
    customerContactData,
    loadingCustomerContact,
    errorLoadingCustomerContact,
    loadedCustomerContact,
  ];
}

export default useCustomerContact;
