import {
  customerLocation,
  customerName,
  financeApproval,
  isCustomerInformedAboutOutstanding,
  isFirstTimeCustomer,
  isPaymentOutstanding,
  natureOfProblem,
  salesApproval,
  spindleDispatchOnlyAfterClearance,
  spindleMake,
  spindleMaxRPM,
  spindleModel,
  spindleReceivedDate,
  spindleSerialNumber,
  spindleType,
  taperSpecifications,
  whyDispatchWithoutClearance,
  technicianName,
} from "./IncomingAlertComponent";

export const IncomingAlertReport = [
  {
    groupHeader: "Customer Details",
    components: [customerName, customerLocation],
  },
  {
    groupHeader: "Payment Details",
    components: [
      isFirstTimeCustomer,
      isPaymentOutstanding,
      isCustomerInformedAboutOutstanding,
      spindleDispatchOnlyAfterClearance,
      whyDispatchWithoutClearance,
    ],
  },
  {
    groupHeader: "Spindle Details",
    components: [
      technicianName,
      spindleSerialNumber,
      spindleModel,
      taperSpecifications,
      spindleMaxRPM,
      spindleReceivedDate,
      spindleType,
      natureOfProblem,
    ],
  },
  {
    groupHeader: "Approval Status",
    components: [financeApproval, salesApproval],
  },
];
